<template>
    <div class="m-2 bg-white shadow block p-5 h-full">
      <div @click="click">
        <div class="flex justify-center">
            <div class="bg-orange-200 flex rounded-full justify-center" style="width: 60px; height: 60px;">
                <b-icon class="text-orange-500 self-center" v-if="item.Icon" :icon="item.Icon" custom-size="mdi-24px"></b-icon>
                <img :src="item.Image" alt="">
            </div>
        </div>
        <div class="flex justify-center">
            <span class="font-semibold text-xl">{{ item.Title }}</span>
        </div>
        <p class="text-center">{{ item.Description }}</p>
      </div>
      <b-modal
        v-model="modal.show" width="80%" :full-screen="isSm">
        <div class="card bg-white w-auto">
            <cms :data="item.Details"></cms>
        </div>
      </b-modal>
    </div>
</template>
<script>
export default {
  components: {
    cms: () => import('@/components/cms')
  },
    props: {
        item: {
            type: Object
        }

    },
  methods: {
    click () {
      if (this.item.Details) {
        this.openModal(this.modalId)
      }
    }
  },
  computed: {
    modalId () {
      return 'icontextcomponent_' + this.item.Title
    },
    modal () {
      return this.$store.getters['modal/getModal'](this.modalId) || { show: false }
    }
  }
}
</script>
